
import { Component, Vue } from 'vue-property-decorator';
import { Family } from '@/models';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from '@firebase/firestore';
import { where } from 'firebase/firestore';
import { db } from '@/firebase';
import { CalendarEvent } from 'vuetify';

@Component({})
export default class CalendarContainer extends Vue {
  private isLoading = false;
  private events: CalendarEvent[] = [];

  private get familyData() {
    return this.$store.getters['family/getFamily'] as Family;
  }

  private get authUser() {
    return this.$store.getters['auth/getAuthUser'];
  }

  mounted(): void {
    this.getMyEvents();
  }

  private async getMyEvents() {
    this.isLoading = true;
    // start date is today at 00:00:00
    const startDate = new Date();
    startDate.setHours(0, 0, 0);
    // end date is today plus 7 days at 23:59:59
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);
    endDate.setHours(23, 59, 59);
    const famId = this.familyData.familyId;
    const q = query(
      collection(db, 'families', famId, 'events'),
      orderBy('start'),
      where('start', '>=', startDate),
      where('start', '<=', endDate),
      where('name', '==', this.authUser.uid),
      limit(50)
    );
    console.log(startDate, endDate, q);
    try {
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const loadedEvents: CalendarEvent[] = [];
        snapshot.forEach((e) => {
          loadedEvents.push({
            ...e.data(),
            start: e.data().start.seconds * 1000,
            end: e.data().end.seconds * 1000,
            id: e.id
          });
        });
        this.events = [...loadedEvents];
      } else {
        console.log('no events found');
        this.events = [];
      }
    } catch (error) {
      console.log(error);
    }

    console.log(this.events);

    this.isLoading = false;
  }

  private getEventColor(event: CalendarEvent) {
    const color =
      this.familyData.dependents.find((dep) => dep.nickname === event.dependent)
        ?.color ?? '#00687a';
    return color;
  }
}
